export const translations = {
  Autoplay: 'Reproducción automática',
  'Autoplay site sounds': 'Reproducción automática de sonidos',
  'Start new game in explore mode.': 'Inicia una nueva partida en modo explorar.',
  'Image selection': 'Selección de imágenes',
  'Letters selection': 'Selección de cartas',
  'Tell again': 'Cuéntalo otra vez',
  'Report an issue': 'Informar de un problema',
  Contribute: 'Contribuir',
  'Game skipped. Starting the next game in {seconds} seconds.': 'Partida saltada. Comenzando la siguiente partida en {seconds} segundos.',
  'Amazing. New game will start in {seconds} seconds.': 'Asombroso. El nuevo juego comenzará en {seconds} segundos.',
  Skip: 'Saltar',
  'Warning: this is alpha version of the app. Please be ready to lose you progress in explore mode once. Sorry for inconvenience.': 'Advertencia: esta es la versión alfa de la aplicación. Por favor, prepárate para perder tu progreso en el modo explorar una vez. Disculpa las molestias.',
  Help: 'Ayuda',
  Leave: 'Deja',
  Contest: 'Concurso',
  'Find rival': 'Buscar rival',
  'Start train': 'Tren de salida',
  Train: 'Tren',
  Amazing: 'Asombroso',
  'Amazing. New round will start in {seconds} seconds.': 'Asombroso. La nueva ronda comenzará en {seconds} segundos.',
  'Well, but not enough. Try again in {seconds} seconds.': 'Bien, pero no lo suficiente. Inténtalo de nuevo en {seconds} segundos.',
  'Very well. New round will start in {seconds} seconds.': 'Muy bien. La nueva ronda comenzará en {seconds} segundos.',
  'Very well.': 'Muy bien.',
  'Well done.': 'Bien hecho.',
  Well: 'Bien',
  'So bad. You can do better!': 'Muy mal. ¡Puedes hacerlo mejor!',
  Explore: 'Explore',
  'What?': '¿Cómo?',
  'You won!': '¡Has ganado!',
  'Solve {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} to reach the {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.': 'Resuelve el {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet}  para llegar al {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}',
  'Hints limit exceeded. Try to solve {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} again to reach the {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.': 'Límite de pistas superado. Intenta resolver {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} de nuevo para llegar a {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.',

  // levels
  Simple: 'Simple',
  Normal: 'Normal',
  Hard: 'Duro'
}
