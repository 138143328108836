export const translations = {
  Autoplay: 'Jeu auto',
  'Autoplay site sounds': 'Sons du site Autoplay',
  'Start new game in explore mode.': 'Commencer une nouvelle partie en mode exploration.',
  'Image selection': 'Sélection des images',
  'Letters selection': 'Sélection de lettres',
  'Tell again': 'Recommencez',
  'Report an issue': 'Signaler un problème',
  Contribute: 'Contribuer',
  'Game skipped. Starting the next game in {seconds} seconds.': 'Jeu ignoré. Début de la partie suivante dans {seconds} secondes.',
  'Amazing. New game will start in {seconds} seconds.': 'Incroyable. Le nouveau jeu commencera dans {seconds} secondes.',
  Skip: 'Sauter',
  'Warning: this is alpha version of the app. Please be ready to lose your progress in explore mode once. Sorry for inconvenience.': "Avertissement : il s'agit d'une version alpha de l'application. Soyez prêt à perdre votre progression en mode exploration une fois. Désolé pour la gêne occasionnée.",
  Help: 'Aide',
  Leave: 'Laisser',
  Contest: 'Concours',
  'Find rival': 'Trouver un concurrent',
  'Start train': 'Train de départ',
  Train: 'Former',
  Amazing: 'Incroyable',
  'Amazing. New round will start in {seconds} seconds.': 'Incroyable. Le nouveau tour commencera dans {seconds} secondes.',
  'Well, but not enough. Try again in {seconds} seconds.': 'Bien, mais pas assez. Réessayez dans {seconds} secondes.',
  'Very well. New round will start in {seconds} seconds.': 'Très bien. Le nouveau tour commencera dans {seconds} secondes.',
  'Very well': 'Très bien',
  'Well done.': 'Bien joué.',
  Well: 'Bien',
  'So bad. You can do better!': 'Dommage. Tu peux faire mieux!',
  Explore: 'Explorer',
  'What?': 'Quoi?',
  'You won!': 'Tu as gagné',
  'Solve {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} to reach the {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.': 'Résolvez {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} pour atteindre {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.',
  'Hints limit exceeded. Try to solve {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} again to reach the {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.': 'Hints limit exceeded. Limite d\'indices dépassée. Essayez de résoudre {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} à nouveau pour atteindre le {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.',

  // levels
  Simple: 'Simple',
  Normal: 'Normale',
  Hard: 'Dur'
}
