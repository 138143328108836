export const translations = {
  Autoplay: 'Авто-запуск',
  'Autoplay site sounds': 'Проигрывать звуки сайта',
  'Start new game in explore mode.': 'Начать новую игру в режиме исследования.',
  'Image selection': 'Подбор изображения',
  'Letters selection': 'Подбор букв',
  'Tell again': 'Повторить',
  'Report an issue': 'Сообщить о проблеме',
  Contribute: 'Поучаствовать',
  'Game skipped. Starting the next game in {seconds} seconds.': 'Игра пропущена. Начало новой игры через {seconds} секунд.',
  'Amazing. New game will start in {seconds} seconds.': 'Великолепно. Новая игра начнется череч {seconds} секунд.',
  Skip: 'Пропустить',
  'Warning: this is alpha version of the app. Please be ready to lose you progress in explore mode once. Sorry for inconvenience.': 'Внимание: это альфа версия приложения. Пожалуйста будь готов что прогресс будет потерян. Приносим свои извинения.',
  Help: 'Помощь',
  Leave: 'Выйти',
  Contest: 'Состязание',
  'Find rival': 'Найти соперника',
  'Start train': 'Начать тренировку',
  Train: 'Тренировка',
  Amazing: 'Великолепно',
  'Amazing. New round will start in {seconds} seconds.': 'Великолепно. Новый раунд начнется через {seconds} секунд.',
  'Well, but not enough. Try again in {seconds} seconds.': 'Хорошо, но не достаточно. Попробуй снова через {seconds} секунд.',
  'Very well. New round will start in {seconds} seconds.': 'Очень хорошо. Новый раунд начнется через {seconds} секунд.',
  'Very well.': 'Очень хорошо.',
  'Well done.': 'Хорошо.',
  Well: 'Хорошо',
  'So bad. You can do better!': 'Плохо. Ты можешь лучше!',
  Explore: 'Прохождение',
  'What?': 'Как?',
  'You won!': 'Ты выиграл!',
  'Solve {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} to reach the {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.': 'Реши {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} чтобы перейти к {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.',
  'Hints limit exceeded. Try to solve {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} again to reach the {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.': 'Количество подсказок превышено. Попробуй решить {firstUnsolvedGameTopic}#{firstUnsolvedGameTopicSet} снова чтобы перейти к {secondUnsolvedGameTopic}#{secondUnsolvedGameTopicSet}.',

  // levels
  Simple: 'Простой',
  Normal: 'Средний',
  Hard: 'Трудный'
}
